//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import { addApplicant } from '../../redux/actions';
import Autocomplete from '../Select/Autocomplete';
// import DisplayFormikState from '../DisplayFormikState';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem',
    width: '30rem',
    overflow: 'auto',
  },
  resize: {
    fontSize: 14,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  error: string,
  submitting: boolean,
  addApplicant: Function,
  values: Object,
  errors: Object,
  handleChange: Function,
  resetForm: Function,
  history: Object,
  classes: Object,
  location: Object,
};
const plan = [
  { _id: 'Software Engineer- DS Algos and OOD', name: 'Software Engineer- DS Algos and OOD' },
  // {
  //   _id: 'Software Engineer - Projects',
  //   name: 'Software Engineer - Projects/Full Stack Web Development',
  // },
  { _id: 'Data Science', name: 'Data Science/Engineer Cohort' },
  { _id: 'System Design', name: 'System Design Cohort' },
];
class AddApplicantForm extends Component<Props> {
  render() {
    const {
      submitting,
      error,
      values,
      handleChange,
      errors,
      classes,
      addApplicant,
      resetForm,
      history,
    } = this.props;
    return (
      <form
        style={{
          display: 'flex',
          width: '70%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          const refereeToken = window.location.href.split('?')[1];
          values.refereeToken = refereeToken;
          addApplicant(values, history);
          resetForm();
        }}
      >
        <div className={classes.container}>
          <h5>Apply to S30</h5>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Submitting Application...</p>}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Submitting Application...</p>}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />
          <TextField
            required
            variant="outlined"
            value={values.firstName}
            onChange={handleChange}
            id="firstName"
            label="First Name"
            placeholder="Jane"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            helperText={errors.firstName}
          />
          <TextField
            required
            variant="outlined"
            value={values.lastName}
            onChange={handleChange}
            id="lastName"
            label="Last Name"
            placeholder="Doe"
            margin="normal"
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            helperText={errors.lastName}
          />
          <TextField
            id="email"
            label="Email"
            type="email"
            value={values.email}
            onChange={handleChange}
            name="email"
            placeholder="Enter gmail address only please"
            autoComplete="email"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            helperText={errors.email}
          />
          <TextField
            required
            variant="outlined"
            value={values.phoneNumber}
            onChange={handleChange}
            id="phoneNumber"
            label="Phone Number"
            placeholder="001-9876944954"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            helperText={errors.phoneNumber}
          />
          <TextField
            required
            id="hoursCommitment"
            label="How many hours you can spend for Interview Prep daily?"
            value={values.hoursCommitment}
            onChange={handleChange}
            name="hoursCommitment"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            helperText={errors.hoursCommitment}
          />
          <TextField
            id="school"
            label="School"
            value={values.school}
            onChange={handleChange}
            name="school"
            placeholder="San Jose State University"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            helperText={errors.school}
          />
          <TextField
            id="linkedIn"
            label="LinkedIn Profile Link"
            value={values.linkedIn}
            onChange={handleChange}
            name="linkedIn"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            helperText={errors.linkedIn}
          />
          <TextField
            id="month"
            label="Starting Month of Cohort"
            value={values.month}
            onChange={handleChange}
            name="month"
            placeholder="August"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            helperText={errors.month}
          />
        </div>
        <div className={classes.container}>
          <TextField
            required
            id="leetCodeProblemsCompleted"
            label="Number of LeetCode problems solved till now?"
            value={values.leetCodeProblemsCompleted}
            onChange={handleChange}
            name="leetCodeProblemsCompleted"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            helperText={errors.leetCodeProblemsCompleted}
          />
          <TextField
            required
            id="whyS30"
            label="Why do you want to join S30?"
            value={values.whyS30}
            onChange={handleChange}
            name="whyS30"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            helperText={errors.whyS30}
          />
          {/* <TextField
            variant="outlined"
            value={values.discountCoupon}
            onChange={handleChange}
            id="discountCoupon"
            label="Discount Coupon"
            placeholder=""
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            helperText={errors.discountCoupon}
          /> */}

          <Autocomplete
            required
            value={values.plan}
            handleChange={handleChange('plan')}
            label="Course Type"
            suggestions={plan}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              required
              id="termsOfService"
              onChange={handleChange}
              color="primary"
              value={values.termsOfService}
            />
            <>I agree to Terms of Service of S30</>
          </div>
          <button type="submit">Submit</button>
        </div>

        {/* <DisplayFormikState {...values} /> */}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  error: state.applicantState.error,
  submitting: state.applicantState.submitting,
});

const mapDispatchToProps = dispatch => ({
  addApplicant: (values, history) => dispatch(addApplicant(values, history)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddApplicantForm));
